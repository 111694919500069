﻿@import "../modules/_definitions.scss";
@import "../modules/_mixins.scss";

.accordion-container {
    margin: 30px 0;
}

.accordion-panel {
    display: none;
    margin-left: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    //slideToggle function jumps when opening the panel if there is a margin top inside the panel and overflow hidden is not set.
    overflow: hidden;

    > p {
        margin-top: 0;
    }

    border-bottom: 1px solid $color-dark-red;
    padding-bottom: 10px;
}

.accordion-toggler {
    @include margin(0, 0, 10px, 0);
    min-height: 24px;
    max-width: fit-content;

    &:hover {
        .toggle-wrapper {
            background-color: $color-dark-red;
        }

        .button-toggler {
            color: $color-dark-red;
        }

        .button-content {
            border-bottom: 1px solid currentColor;
        }
    }
}

.button-toggler-container {
    cursor: pointer;
}

.button-toggler,
.button-content {
    outline: none;
}

.button-toggler {
    height: 100%;
    max-width: calc(100% - 47px);
    display: block;
    background: none;
    border: none;
    color: $color-red;
    @include font-size(18);
    @include line-height(24);
    font-weight: 700;
    margin: 0 15px 0 32px;
    padding-left: 0;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.button-content {
    border-bottom: 1px solid transparent;
}

//only outline when tabbing into focus
.button-toggler:focus > .button-content {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
}

.toggle-wrapper {
    height: 24px;
    width: 24px;
    background-color: $color-red;
    border-radius: 50%;
    position: relative;
    float: left;

    .horizontal-bar,
    .vertical-bar {
        position: absolute;
        display: block;
        background-color: white;
        top: calc((50%) - 1px);
        left: calc((50%) - 6px);
        width: 12px;
        height: 2px;
        transition: transform linear 0.2s;
    }
}

.vertical-bar {
    transform: rotate(90deg);
}

.opened {
    display: block;

    .vertical-bar {
        transform: rotate(135deg);
    }

    .horizontal-bar {
        transform: rotate(45deg);
    }

    .toggle-wrapper {
        background-color: $color-dark-red;
    }

    .button-toggler {
        color: $color-dark-red;
    }
}

@media(min-width: $sm-min-width) {
    .accordion-panel {
        margin: 0 0 0 40px;
    }

    .accordion-toggler {
        min-height: 30px;
    }

    .toggle-wrapper {
        height: 30px;
        width: 30px;

        .horizontal-bar,
        .vertical-bar {
            width: 14px;
            height: 2px;
            top: calc((50%) - 1px);
            left: calc((50%) - 7px);
        }
    }

    .button-toggler {
        @include font-size(20);
        @include line-height(24);
        margin: 0 0 0 40px;
        max-width: calc(100% - 40px);
        min-height: 30px;
    }
}
