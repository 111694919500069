﻿@import "../modules/_definitions.scss";
@import "../modules/_mixins.scss";

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    @include margin(0,0,$pad*1.5,0);
}

.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
