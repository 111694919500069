﻿@use "sass:math";
@import "../modules/_definitions.scss";
@import "../modules/_mixins.scss";

.breadcrumb-container {

    .breadcrumbs {
        @include margin(0);
        @include padding($pad * 1.5, 0);
        @include font-size(14);
        @include line-height(17);

        li {
            float: left;
            font-weight: 600;

            a {
                @include margin(0, math.div($pad,4), 0, 0);
            }

            span {
                color: #000;
            }
        }
    }

    .divider {
        @include margin(0, math.div($pad,4), 0, 0);
    }
}
