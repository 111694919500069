﻿@use "sass:math";
@import "../modules/_definitions.scss";
@import "../modules/_mixins.scss";
@import "components.scss";

$content-page-container-padding: $pad*14;
$content-padding-sm: $pad*3;

html {
    //Native html-elements
    @include font-size(16);

    body {
        font-family: $global-font-source-sans-pro;
        background-color: #fff;
        @include font-size(16);
        @include line-height(20);
        color: $color-black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    //General classes
    .button-red {
        @include padding($pad, $pad * 2);
        @include font-size(18);
        @include line-height(22);
        background-color: $color-red;
        color: #fff;
        border: none;
        //safari stuff
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        &:hover, &:active, &.current {
            background-color: $color-darkest-red;
        }
    }

    .bold-underlined-link {
        font-weight: 600;
        text-decoration: underline;
    }

    .link-button-dark-red {
        @include padding($pad, $pad * 2);
        @include font-size(16);
        @include line-height(20);
        background-color: $color-dark-red;
        color: #fff;
        display: block;
        cursor: pointer;
        text-align: center;
        margin: 0 auto #{$pad}px auto;
        width: 25%;
        min-width: 200px;
        text-decoration: none;
        //safari stuff
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        &:visited {
            color: #fff;
        }

        &:hover, &:active, &.current {
            background-color: $color-darkest-red;
            color: #fff;
        }
    }

    .link-button-lighter-red {
        @include padding($pad, $pad * 2);
        @include font-size(16);
        @include line-height(20);
        background-color: $color-lighter-red;
        color: $color-dark-red;
        display: block;
        cursor: pointer;
        text-align: center;
        margin: 0 auto #{$pad}px auto;
        width: 25%;
        min-width: 200px;
        font-weight: 600;
        text-decoration-line: none;
        //safari stuff
        -webkit-appearance: none;
        -webkit-border-radius: 0;

        &:visited {
            color: $color-dark-red;
        }

        &:hover, &:active, &.current {
            background-color: $color-white;
            color: $color-dark-red;
            text-decoration: none;
        }
    }

    .navigation-heading {
        @include font-size(19);
        @include line-height(23);
        text-transform: uppercase;
    }

    .link-button-red {
        @extend .link-button-dark-red;
        text-decoration-line: none;
        background-color: $color-red;
        white-space: nowrap;
        width: fit-content;

        &:hover, &:active, &.current {
            background-color: $color-dark-red;
        }
    }

    .external_link {
        display: inline;

        &:after {
            background-position: 3px 5px;
            background-size: 12px;
            content: "\A0\A0\A0\A0\A0";
            background-image: url("/Content/Images/Gfx/icon_external_link_blue.svg");
            background-repeat: no-repeat;
        }

        &:hover:after {
            background-image: url("/Content/Images/Gfx/icon_external_link_hover.svg");
        }
    }

    .white-text {
        .external_link {
            color: #fff;

            &:after {
                background-image: url("/Content/Images/Gfx/icon_external_link_white.svg");
            }

            &:hover:after {
                background-image: url("/Content/Images/Gfx/icon_external_link_white.svg");
            }
        }
    }

    .important_information, .warning {
        display: block;
        @include padding(7, $pad, $pad, $pad*2.3);
        @include margin($pad, 0, math.div($pad,2), 0);
    }

    .important_information {
        border-left: 10px solid $important-information-color;
        @include padding(12, 0, 12, $pad);
        @include margin($pad, 0, $pad, 0);
    }

    .warning {
        background: url("/Content/Images/Gfx/icon_obs.svg") no-repeat 0 0;
        background-size: 26px 32px;
        font-weight: bold;
    }

    .download_document {
        background: url("/Content/Images/Gfx/icon_document.svg") no-repeat 0 0;
        background-size: 23px 33px;
        display: block;
        //font-weight: 700;
        @include padding(0, 0, 0, $pad*3.2);
        @include margin($pad, 0);
        min-height: 45px;
    }

    .list-content {
        ul {
            @include margin(0, 0, $pad*2, 0);

            li {

                @include padding(math.div($pad,1.5), 0);

                a {
                    @include font-size(22);
                    @include line-height(24);
                    @include margin(0, 0, $pad, 0);
                    font-weight: 700;
                }

                span {
                    @include font-size(16);
                    @include line-height(20);
                    display: block;
                }
            }
        }
    }

    .paging-result {
        @include margin(0,0,$pad,0);

        h3 {
            font-weight: 400;
        }
    }

    .paging-container {
        .link-button-red {
            min-width: 130px;
            max-width: 160px;
            @include padding(10,math.div($pad,2));
            height: 44px;

            @include line-height(24);
        }

        a, h3 {
            float: left;
        }

        .previous {
            padding-left: 18px;
            background-image: url(/Content/Images/Gfx/arrow_left.svg);
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: 0px 10px;
        }

        .next {
            float: right;
            background-image: url(/Content/Images/Gfx/arrow_right.svg);
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: calc(100% - 1px) 10px;
        }

        .paging-heading {
            font-weight: 400;
        }
    }
    // ========== För t ex innehåll i egenskaper av typen XHtmlString ==========
    .text-content {

        h1 {
            @include margin(0,0,$pad * 1.5, 0);
        }

        h2 {
            @include margin($pad*2, 0, math.div($pad,2), 0);
        }

        h3 {
            @include margin($pad * 1.5, 0, math.div($pad,4), 0);
        }

        p {
            @include margin(0, 0, $pad * 0.75, 0);
        }

        ul {
            @include margin(0, 0, $pad,0);
            @include padding(0, 0, 0, 24);
            list-style-type: disc;

            li {
                @include font-size(16);
                @include line-height(20);
                @include padding(math.div($pad,4), 0);

                a {
                    vertical-align: top;
                }
            }
        }

        ol {
            @include margin(0, 0, $pad, 0);
            @include padding(0, 0, 0, 24);

            li {

                @include font-size(16);
                @include line-height(20);
                @include padding(math.div($pad,4), 0);

                a {
                    vertical-align: top;
                }
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .visually-hidden {
        position: absolute;
        left: -10000px;
        color: #fff;
        width: 1px;
        height: 0px;
    }

    .no-padding {
        padding: 0;
    }

    aside.links {
        @include margin($pad, 0);
        @include padding($pad, 0, 0, 0);
        border-top: 9px solid $color-dark-red;

        h2 {
            @include margin(0, 0, math.div($pad,2), 0);
        }

        > ul {
            padding: 0;
            list-style-type: none;

            > li {
                @include font-size(16);
                @include line-height(25);
                @include padding(0, 0, math.div($pad,2), 0);

                > a {
                    @include font-size(16);
                    @include line-height(25);

                    &.external_link {
                        &:after {
                            background-position: 3px 5px;
                            background-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:$xs-min-width) {
    html {
        .body-container-padded, .body-container-padded-left {
            @include padding(0, $content-padding-sm);
        }
    }
}

@media (min-width:$sm-min-width) {
    html {
        body {
            @include font-size(19);
            @include line-height(25);
        }

        .link-button-red, .link-button-dark-red, .link-button-lighter-red {
            @include font-size(19);
            @include line-height(22);
            max-width: 300px;
        }

        .external_link {
            &:after {
                background-position: 3px 6px;
                background-size: 14px;
            }
        }

        .download_document {
            @include padding(0, 0, 0, $pad*2.5);
            @include margin($pad, 0, $pad, 0);
            width: 85%;
        }

        .important_information, .warning {
            @include padding(7, $pad, $pad, $pad*3);
            @include margin($pad*2, 0, $pad, 0);
        }

        .warning {
            background-size: 33px 40px;
        }

        .important_information {
            @include padding(12, 0, 12, $pad);
            @include margin($pad*2, 0, $pad*2, 0);
        }

        .list-content {
            ul {
                li {

                    @include padding($pad, 0);

                    a {
                        @include font-size(26);
                        @include line-height(30);
                    }

                    span {
                        @include font-size(16);
                        @include line-height(25);
                    }
                }
            }
        }

        .paging-container {
            .link-button-red {
                @include padding($pad,$pad*1.5);
                min-width: 170px;
                max-width: 200px;
                height: 50px;

                @include line-height(20);
            }


            .previous {
                background-position: 10px 12px;
                //  background-size: 35px 35px;
            }

            .next {
                // background-size: 35px 35px;
                background-position: calc(100% - 10px) 12px;
            }
        }

        .paging-result {
            @include margin(0,0,$pad*2,0);

            h3 {
                font-weight: 400;
            }
        }

        .text-content {
            p {
                @include margin(0, 0, $pad, 0);
            }

            ul, ol {
                li {
                    @include line-height(25);
                    @include font-size(19);
                }
            }
        }
    }
}

@media (min-width:$md-min-width) {
    html {

        .body-container {
            max-width: $site-max-width;
            margin-left: auto;
            margin-right: auto;
        }

        .body-container-padded {
            @extend .body-container;
            @include padding(0, $content-page-container-padding);
        }

        .body-container-padded-left {
            @extend .body-container;
            padding-left: #{$content-page-container-padding}px;
            padding-right: #{$pad}px;
        }

        .cookie-button {
            @include padding($pad, $pad*2);
        }

        .cookie-alert {
            span {
                @include margin(0);
            }
        }
    }
}

@media (min-width:$lg-min-width) {
    html {
        .paging-container {
            .link-button-red {
                @include padding($pad,$pad*1.5);
                max-width: 100px;
            }
        }

        .sidebar-container {
        }
    }
}
