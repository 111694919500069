﻿@use "sass:math";
@import "../modules/_definitions.scss";
@import "../modules/_mixins.scss";

$menu-border-sm: solid 1px $color-light-red;
$menu-border-md: solid 2px $color-light-red;
$menu-md-min-height: 60px;
$menu-item-md-height: 73;
$breakpoint-one-row-logo: 364px;

#header {
    background-color: $color-red;

    .skiplink {
        position: absolute;
        left: -9999em;

        &:focus {
            display: block;
            @include padding(20, $pad*1.5);
            z-index: 100;
            background: $color-red;
            color: #fff;
            box-shadow: 4px 4px 8px rgba(0,0,0,.3);
            margin-right: 16px;
            left: 114px;
            top: 16px;
            min-height: 60px;
        }
    }

    .site-logo {
        width: 100%;
        z-index: 100;
        top: 8px;
        left: 5px;
        width: 56px;
        height: 56px;
    }

    .logo-container {
        padding: 8px 4px;
        text-decoration: none;
        float: left;
        display: inline-flex;
        flex-direction: row;
    }

    .site-header-text {
        margin-top: 8px;
        margin-bottom: auto;
        margin-left: 10px;

        span {
            color: #fff;
            @include font-size(11);
            @include line-height(13);
            @include margin(0, 10, 0, 0);
            letter-spacing: 2px;
            font-weight: 600;
        }

        h1 {
            @include font-size(18);
            @include line-height(22);
            font-weight: 700;
            @include margin(0);
            color: #fff;
        }
    }

    .top-menu-container {
        @include padding(0);
    }

    .search-link-container {
        background: $color-light-red;
        float: right;
        text-align: center;
        padding: 15px 20px;

        &.active {
            background-color: $color-lightest-red;

            .search-logo {
                background-color: $color-lightest-red;
            }
        }
    }

    .search-logo {
        background-color: $color-light-red;
        @include font-size(16);
        @include line-height(19);
        text-decoration: none;
        width: 40px;
        padding: 0;
        color: $color-dark-red;
        text-align: center;
        display: flex;
        flex-direction: column;

        span {
            display: inline-block;
            @include font-size(16);
            @include line-height(19);
            font-weight: 400;
            display: flex;
            flex-direction: column;
            text-align: center;

            &:before {
                content: '';
                background-image: url(/Content/Images/Gfx/icon_sok_rod3.svg);
                background-repeat: no-repeat;
                width: 22px;
                height: 22px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 4px;
                display: block;
            }

            &.search-logo-expanded {
                display: none;

                &:before {
                    background-image: url(/Content/Images/Gfx/icon_kryss_22x22.svg);
                }
            }
        }

        &.active {
            span {
                // hides both "Sok" and "Stäng"
                display: none;
            }

            .search-logo-expanded {
                // shows only "Stäng"
                display: block;
            }
        }
    }

    .header-navigation-container {

        .navigation {
            float: left;
            width: 100%;
            background-color: $color-red;
            border: none;
            border-left: solid 1px $color-light-red;
            border-bottom: solid 1px $color-light-red;

            ul {
                display: flex;
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;

                li {
                    display: flex;
                    width: 50%;
                    border-top: $menu-border-sm;

                    &:hover, &:active, &.current {
                        background-color: $color-light-red;

                        .menu-item {
                            color: $color-dark-red;
                        }
                    }

                    &.active-menu-item {
                        background-color: $color-dark-red;

                        .menu-item {
                            color: $color-white;
                        }
                    }

                    &:not(:last-child) {
                        border-right: $menu-border-sm;
                    }
                }
            }
        }

        @include padding(0);

        .menu-item {
            @include padding($pad, 5px);
            @include font-size(16);
            @include line-height(19);
            font-weight: 400;
            color: #fff;
            display: flex;
            align-items: center;
            height: 100%;
            cursor: pointer;
            text-align: center;
            margin: auto;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            &:hover, &:active {
                text-decoration: none;
            }

            &:hover, &:active {
                background-color: $color-light-red;
                color: $color-dark-red !important;
            }
        }
    }

    .search-container {
        background-color: $color-lightest-red;
        padding: 20px;
        @include font-size(17);
        @include line-height(20);
        width: 100%;
        float: left;
        display: none;

        input, textarea {
            -webkit-appearance: none;
            -webkit-border-radius: 0;
        }

        form {
            display: flex;
            justify-content: center;
        }
    }

    .search-field {
        width: 80%;
        flex-basis: 80%;
        border: none;
        margin-right: 8px;
        border-bottom: 4px solid $color-red;
        @include padding($pad, math.div($pad,2), $pad, 8px);
    }

    .search-button {
        width: 20%;
        min-width: 80px;
        background: none;
        border: none;
        background-color: $color-red;
        color: #fff;
        @include padding(math.div($pad,4), $pad);

        &:hover, &:active {
            background-color: $color-darkest-red;
        }
    }
}

@media (min-width: $breakpoint-one-row-logo) {
    #header {
        .skiplink {
            &:focus {
                min-height: 40px;
                @include padding(math.div($pad,1.5), $pad*2.5);
            }
        }
    }
}

@media (max-width: 410px) {
    #header {
        .site-header-text span {
            letter-spacing: 0.4px;
        }

        .header-navigation-container {
            .menu-item {
                padding-left: .25rem;
                padding-right: .25rem;
            }
        }
    }
}

@media (min-width:$xs-min-width) {
    #header {
        .skiplink {
            &:focus {
                left: 150px;
            }
        }

        .site-header-text {
            h1 {
                @include font-size(21);
                @include line-height(25);
            }
        }
    }
}

@media (min-width:$sm-min-width) {
    #header {
        .skiplink {
            &:focus {
                min-height: 46px;
            }
        }

        .header-navigation-container {
            .navigation {

                ul {
                    li {
                        width: 25%;
                    }
                }
            }
        }
    }
}

@media (min-width:$md-min-width) {
    #header {
        .header-container {
            position: relative;
        }

        .skiplink {
            &:focus {
                left: 190px;
                top: 10px;
                @include padding($pad, 35);
            }
        }

        .logo-container {
            width: auto;
            float: left;
            position: absolute;
            //move focus outline to child element
            &:focus {
                outline: none;

                .site-logo {
                    box-shadow: 4px 4px 8px rgba(0,0,0,.3);
                    //firefox and IE
                    outline: 1px dotted white;
                    //copied from bootstrap link outline style
                    outline: 5px auto -webkit-focus-ring-color;
                    outline-offset: -2px;
                }
            }
        }

        .site-logo {
            height: 157px;
            width: 157px;
            margin-left: 30px;
        }

        .site-header-text {
            margin-left: 1rem;
            margin-top: 4px;

            span {
                &:last-child {
                    @include margin(0);
                }
            }
        }

        .top-menu-container {
            float: right;
            width: auto;
            position: relative;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
        }

        .search-link-container {
            min-height: 75px;
            min-width: 80px;
            cursor: pointer;
            text-align: center;
            @include padding(0);
            border: none;
            border-left: solid 1px $color-light-red;
            border-bottom: solid 1px $color-light-red;
            display: flex;

            &:hover {
                background-color: $color-darkest-red;
            }

            &.active {
                background-color: $color-darkest-red;
                border-bottom: none;
            }
        }

        .search-logo {
            background-color: $color-red;
            color: $background-color-white;
            @include font-size($pad);
            @include line-height(25);
            width: 100%;
            padding: 15px 20px;

            span {
                flex-direction: row;
                align-items: center;
                width: 66px;
                margin: auto;

                &.search-logo-closed:before {
                    background-image: url(/Content/Images/Gfx/icon_sok_vit.svg);
                }

                &.search-logo-expanded:before {
                    background-image: url(/Content/Images/Gfx/icon_kryss_vit.svg);
                }

                &:before {
                    margin: 0;
                    margin-right: 4px;
                    background-position: center;
                    background-size: inherit;
                }
            }

            &.active {
                span {
                    display: none;

                    &.search-logo-expanded {
                        display: inline-flex;
                    }
                }
            }

            &.active, &:hover {
                background-color: $color-light-red;
                color: $color-dark-red !important;

                span {
                    &.search-logo-closed:before {
                        background-image: url(/Content/Images/Gfx/icon_sok_rod3.svg);
                    }

                    &.search-logo-expanded:before {
                        background-image: url(/Content/Images/Gfx/icon_kryss_22x22.svg);
                    }
                }
            }
        }

        .header-navigation-container {
            .navigation {
                width: auto;

                ul {
                    display: flex;
                    flex-direction: row;
                    height: 100%;

                    li {
                        background-color: $color-red;
                        min-height: 75px;
                        width: auto;
                        cursor: pointer;
                        border-top: none;

                        &:nth-child(n) {
                            border-right: $menu-border-sm;
                        }
                    }
                }
            }

            .menu-item {
                @include line-height(20);
                @include padding($pad, 8px);
            }
        }

        .header-navigation-border {
            nav {
                ul {
                    li {
                        border-bottom: $menu-border-sm;
                    }
                }
            }
        }

        .search-container {
            order: 3;
            width: 100%;
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 1000;
            width: 100%;
        }

        .search-field {
            background-position: 4px;
            background-size: 35px 35px;
            @include font-size(24);
            @include padding(math.div($pad,2));
        }

        .search-button {
            @include font-size(20);
            @include padding(math.div($pad,4), $pad*2);
        }
    }
}

@media (min-width: $lg-min-width) {

    #header {
        .header-container {
            max-width: $site-max-width;
            margin-left: auto;
            margin-right: auto;
            float: none;
        }

        .header-navigation-container {
            .navigation {
                .menu-item {
                    @include padding($pad, 20px);
                }
            }
        }
    }
}
