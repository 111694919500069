﻿@use "sass:math";
@import "../modules/_definitions.scss";
@import "../modules/_mixins.scss";

.max-width-1200 {
    max-width: 1200px;
}

.footer-main-container {
    background-color: $color-red;
    border-top: 8px solid $color-dark-red;
    color: #fff;

    p {
        margin: 0px;
        padding-bottom: 8px;
    }

    a {
        color: #fff;
        text-decoration: underline;
        text-decoration-color: #FFC2C1;

        &:visited {
            color: #fff;
            text-decoration-color: #FFC2C1;
        }

        &:hover, &:active {
            color: #fff;
            text-decoration-color: #fff;
        }
    }

    .main-content {
        @include margin($pad * 2, 0);
    }

    .section {
        @include margin($pad*1.3,0,0,0);
    }

    .left-content, .center-content {
        @include margin(math.div($pad,4), 0, 0, 0);

        > div {
            margin-bottom: 10px;
        }
    }

    .right-content {
        position: relative;
        padding-top: 107px;

        img {
            position: absolute;
            height: 103px;
            //width: 106px;
            top: -15px;
            @include margin(0,0,$pad*1.5,0);
        }
    }

    .white-text {
        .external_link {
            display: inline;

            &:after {
                background-position: right 6px;
                position: absolute;
            }
        }
    }
}

@media(min-width: $sm-min-width) {
    .footer-main-container {
        h2 {
            @include font-size(24);
            @include line-height(30);
        }

        .cooperation {
            clear: both;
        }
    }
}

@media (min-width:$md-min-width) {
    .footer-main-container {
        .cooperation {
            clear: none;
        }

        .main-content {
            @include margin($pad * 3, 0, $pad * 3.5, 0);
        }

        .white-text {
            .external_link {
                &:after {
                    background-position: right 7px;
                }
            }
        }
    }
}
